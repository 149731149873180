import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import Home from '~/pages/Home';

import Sales from '~/pages/Sales';
import ActivateNft from '~/pages/ActivateNft';
import TokenUp from '~/pages/TokenUp';
import Order from '~/pages/Order';
import Payment from '~/pages/Payment';
import Register from '~/pages/Register';
import Dashboard from '~/pages/Dashboard';
import Resources from '~/pages/Resources';
import Referrers from '~/pages/Referrers';
import Learderboard from '~/pages/Learderboard';
import Wallet from '~/pages/Wallet';
import Winnings from '~/pages/Winnings';
import Links from '~/pages/Links';
import Training from '~/pages/Training';
import TrainingDetails from '~/pages/TrainingDetails';
import Profit from '~/pages/Profit';
import News from '~/pages/News';
import Nft from '~/pages/Nft';
import NftHistory from '~/pages/NftHistory';
import NftSaved from '~/pages/NftSaved';
import NftCollectionsPrivate from '~/pages/NftCollectionsPrivate';
import NftCollections from '~/pages/NftCollections';
import Matrix5X6 from '~/pages/Matrix5X6';
import UserProfile from '~/pages/UserProfile';
import Game from '~/pages/Game';
import TokenVault from '~/pages/TokenVault';
import ValueCrypto from '~/pages/ValueCrypto';
import Subscriptions from '~/pages/Subscriptions';
import Chatbot from '~/pages/Chatbot';
import LpNft from '~/pages/LpNft';
import NotFound from '~/pages/NotFoundPage';

import MyNfts from '~/pages/MyNfts';
import PageSwap from '~/pages/PageSwap';
import Launchpad from '~/pages/Launchpad';
import ProductRoadmap from '~/pages/ProductRoadmap';
import Discover from '~/pages/Discover';
import AiAvatar from '~/pages/AiAvatar';
import AiExpect from '~/pages/AiExpect';
import SignupAutoaffiliate from '~/pages/SignupAutoaffiliate';
import OrderOption from '~/pages/OrderOption';
import Wr from '~/pages/Wr';
import BtcSignals from '~/pages/BtcSignals';
import SmartTrade from '~/pages/SmartTrade';

const routes: React.FC = () => {
  return (
    <Switch>
      <Route path={`${process.env.PUBLIC_URL}/`} exact component={Home} />
      {/* <Route
        path={`${process.env.PUBLIC_URL}/i/:reference`}
        exact
        component={Home}
      /> */}
      {/* <Route
        path={`${process.env.PUBLIC_URL}/i/:reference/:placementRef?/:username?`}
        exact
        component={SignupAutoaffiliate}
      /> */}
      <Route
        path={`${process.env.PUBLIC_URL}/swap`}
        exact
        component={PageSwap}
      />
      <Route path={`${process.env.PUBLIC_URL}/try/i/:slug`} component={LpNft} />

      <Route
        path={`${process.env.PUBLIC_URL}/roadmap`}
        exact
        component={ProductRoadmap}
      />

      <Route
        path={`${process.env.PUBLIC_URL}/discover`}
        exact
        component={Discover}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/get-your-free-nft`}
        component={Sales}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/how-to-participate`}
        component={ActivateNft}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/earn-even-more`}
        component={TokenUp}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/activate-your-profit-centers`}
        component={Order}
        exact
        isPrivate
      />

      <Route
        path={`${process.env.PUBLIC_URL}/activate-your-profit-centers/:slug`}
        component={Order}
        isPrivate
      />

      <Route
        path={`${process.env.PUBLIC_URL}/final-step`}
        component={Payment}
        isPrivate
      />

      <Route
        path={`${process.env.PUBLIC_URL}/ai-avatar`}
        component={AiAvatar}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/what-to-expect`}
        component={AiExpect}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/finalizar-cadastro`}
        component={Register}
      />

      <Route
        path={`${process.env.PUBLIC_URL}/dashboard`}
        component={Dashboard}
        isPrivate
        exact
      />

      <Route
        path={`${process.env.PUBLIC_URL}/products/ai-art/nft-history`}
        component={NftHistory}
        isPrivate
      />

      <Route
        path={`${process.env.PUBLIC_URL}/products/ai-art/collections/:slug`}
        component={NftCollections}
        isPrivate
      />

      <Route
        path={`${process.env.PUBLIC_URL}/products/ai-art/collections-private`}
        component={NftCollectionsPrivate}
        exact
        isPrivate
      />

      <Route
        path={`${process.env.PUBLIC_URL}/products/ai-art/:slug`}
        isPrivate
        component={Nft}
      />

      <Route
        path={`${process.env.PUBLIC_URL}/news`}
        component={News}
        isPrivate
        exact
      />

      <Route
        path={`${process.env.PUBLIC_URL}/business/resources`}
        component={Resources}
        isPrivate
      />

      <Route
        path={`${process.env.PUBLIC_URL}/my-nfts`}
        component={MyNfts}
        isPrivate
      />

      <Route
        path={`${process.env.PUBLIC_URL}/business/referrals`}
        component={Referrers}
        isPrivate
      />

      <Route
        path={`${process.env.PUBLIC_URL}/activities/profit-center-:slug`}
        component={Profit}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/business/matrix`}
        component={Matrix5X6}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/business/wallet`}
        component={Wallet}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/token-vault`}
        component={TokenVault}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/activities/earnings`}
        component={Winnings}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/business/links`}
        component={Links}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/learning`}
        exact
        component={Training}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/learning/:slug`}
        component={TrainingDetails}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/leaders`}
        component={Learderboard}
        isPrivate
      />

      <Route
        path={`${process.env.PUBLIC_URL}/user-profile`}
        component={UserProfile}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/products/launchpad`}
        component={Launchpad}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/products/cube`}
        component={Game}
        isPrivate
      />

      <Route
        path={`${process.env.PUBLIC_URL}/products/paige`}
        component={Chatbot}
        isPrivate
        exact
      />
      <Route
        path={`${process.env.PUBLIC_URL}/products/paige/:slug`}
        component={Chatbot}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/tokenomics`}
        component={ValueCrypto}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/subscriptions`}
        component={Subscriptions}
        isPrivate
      />

      <Route
        path={`${process.env.PUBLIC_URL}/aa/:reference/:placementRef?/:username?`}
        component={SignupAutoaffiliate}
        exact
      />

      <Route
        path={`${process.env.PUBLIC_URL}/aa`}
        component={SignupAutoaffiliate}
        exact
      />

      <Route
        path={`${process.env.PUBLIC_URL}/autoaffiliate-member`}
        component={SignupAutoaffiliate}
        exact
      />

      <Route path={`${process.env.PUBLIC_URL}/wr`} component={Wr} exact />

      <Route
        path={`${process.env.PUBLIC_URL}/autoaffiliate-member/order`}
        component={OrderOption}
      />

      <Route
        path={`${process.env.PUBLIC_URL}/btc-signals`}
        component={BtcSignals}
      />

      <Route
        path={`${process.env.PUBLIC_URL}/smart-trade`}
        component={SmartTrade}
        isPrivate
      />

      <Route path="*" component={NotFound} isPrivate />
    </Switch>
  );
};

export default routes;
