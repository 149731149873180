/* eslint-disable no-restricted-syntax */
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import {
  BsArrowDownShort,
  BsArrowRight,
  BsPlus,
  BsPlusSquare,
} from 'react-icons/bs';
import { AiOutlineMessage } from 'react-icons/ai';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import {
  Container,
  Welcome,
  BoxMatrix,
  BoxNft,
  Avatar,
  BoxNewNft,
} from './styles';

import { useAuth } from '~/hooks/Auth';

import activity from '~/assets/icons/activity-icon-button.svg';
import arrow from '~/assets/icons/arrow-icon-button.svg';
import WalletRow from '~/components/WalletRow';
import api from '~/services/api';
import { formatPrice } from '~/utils/format';
import search from '~/assets/icons/search-icon.svg';
import wallpaper from '~/assets/defaults/wallpaper.png';
import Loading from '~/components/Loading';
import logoWhite from '~/assets/logo/logo-p-white.svg';
import rain from '~/assets/icons/rain-icon.svg';
import info from '~/assets/icons/Info-white.svg';
import Input from '~/components/Input';
import ethLogo from '~/assets/logo/eth.png';
import ModalPleaseConfirm from './ModalPleaseConfirm';

interface ITreeResponse {
  tree: {
    id: '1';
    level_1: {
      id: number;
      nft_name: string;
      screen_name: string;
      rank: string;
      nft_img: string;
      total_members: number;
      direct_referrals: number;
      wtd_volume: string;
      mtd_volume: string;
    }[];
    level_2: {
      id: number;
      nft_name: string;
      screen_name: string;
      rank: string;
      nft_img: string;
      total_members: number;
      direct_referrals: number;
    }[];
    level_3: {
      id: number;
      nft_name: string;
      screen_name: string;
      rank: string;
      nft_img: string;
      total_members: number;
      direct_referrals: number;
    }[];
  };
}

interface IUserData {
  id: number;
  nft_name: string;
  screen_name: string;
  rank: string;
  nft_img: string;
  total_members: number;
  direct_referrals: number;
  wtd_volume: string;
  mtd_volume: string;
}

interface IReferrer {
  id: number;
  nft_name: string;
  screen_name: string;
  rank: string;
  nft_img: string;
}

interface IUserMatrix {
  id: number;
  nft_name: string;
  screen_name: string;
  rank: string;
  nft_img: string;
  referrers: IReferrer[];
}

interface IClickButtonTopProps {
  profitCenterId?: number;
  firstLine?: boolean;
  beforeProfitCenterId?: number;
}

const Matrix5x6: React.FC = () => {
  const { user } = useAuth();
  const formRef = useRef<FormHandles>(null);
  const boxNftRef = useRef<HTMLDivElement>(null);
  const boxNewNftRef = useRef<HTMLDivElement>(null);
  const [userData, setUserData] = useState<IUserData>({} as IUserData);
  const [firstId, setFisrtId] = useState(0);
  const [currentUserId, setCurrentUserId] = useState(0);
  const [beforeUserId, setBeforeUserId] = useState<number[]>([]);
  const [usersMatrix, setUsersMatrix] = useState<IUserMatrix[]>([]);
  const [loading, setLoading] = useState(true);
  const [boxPosition, setBoxPosition] = useState({ x: 0, y: 0 });
  const [show, setShow] = useState(false);
  const [showNewNft, setShowNewNft] = useState(false);
  const [signUpInputs, setSignUpInputs] = useState(false);
  const [choiseValue, setChoiseValue] = useState(false);
  const [showModalPleaseConfirm, setShowModalPleaseConfirm] = useState(false);
  const [valueRange, setValueRange] = useState(1);
  const [userMatrixSelected, setUserMatrixSelected] = useState({} as IUserData);

  useEffect(() => {
    api
      .get<ITreeResponse>(`v1/member/tree/${user?.id}`)
      .then((response) => {
        const data = response.data.tree.level_2.map<IUserMatrix>(
          (referrer, index) => {
            const indexData = index * 5;
            const referrerData = response.data.tree.level_3.slice(
              indexData,
              indexData + 5
            );

            return {
              ...referrer,
              referrers: referrerData,
            };
          }
        );

        setUserData({
          ...response.data.tree.level_1[0],
          wtd_volume: formatPrice(
            parseFloat(
              (
                parseInt(response.data.tree.level_1[0].wtd_volume, 10) /
                10 ** 18
              ).toFixed(2)
            )
          ),
          mtd_volume: formatPrice(
            parseFloat(
              (
                parseInt(response.data.tree.level_1[0].mtd_volume, 10) /
                10 ** 18
              ).toFixed(2)
            )
          ),
        });
        setUsersMatrix(data);
        setFisrtId(response.data.tree.level_1[0].id);
        setCurrentUserId(response.data.tree.level_1[0].id);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [user?.id]);

  useEffect(() => {
    setShow(false);

    const buttons = document.querySelectorAll(
      '.btn-ntf'
    ) as unknown as HTMLButtonElement[];

    for (const button of buttons) {
      button.addEventListener('contextmenu', (event) => {
        event.preventDefault();
      });
    }
  }, [currentUserId]);

  const handleOutsideClick = useCallback((e) => {
    if (boxNftRef.current && !boxNftRef.current.contains(e.target)) {
      setShow(false);
    }
    if (boxNewNftRef.current && !boxNewNftRef.current.contains(e.target)) {
      setShowNewNft(false);
      setSignUpInputs(false);
      setChoiseValue(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
  });

  const handleClick = useCallback(
    async ({
      profitCenterId,
      firstLine,
      beforeProfitCenterId,
    }: IClickButtonTopProps) => {
      setLoading(true);
      setUsersMatrix([]);
      try {
        const founded = beforeUserId.find(
          (beforeId) => beforeId === profitCenterId
        );
        if (firstLine) {
          setBeforeUserId([]);
        } else if (founded) {
          const newBeforeUserId = beforeUserId.filter(
            (beforeId) => beforeId !== profitCenterId
          );
          setBeforeUserId(newBeforeUserId);
        } else if (beforeProfitCenterId) {
          setBeforeUserId((state) => [
            ...state,
            currentUserId,
            beforeProfitCenterId,
          ]);
        } else {
          setBeforeUserId((state) => [...state, currentUserId]);
        }

        const response = await api.get<ITreeResponse>(
          `/v1/member/tree/pcenter/${profitCenterId}`
        );
        const data = response.data.tree.level_2.map<IUserMatrix>(
          (referrer, index) => {
            const indexData = index * 5;
            const referrerData = response.data.tree.level_3.slice(
              indexData,
              indexData + 5
            );

            return {
              ...referrer,
              referrers: referrerData,
            };
          }
        );

        setUserData({
          ...response.data.tree.level_1[0],
          wtd_volume: formatPrice(
            parseFloat(
              (
                parseInt(response.data.tree.level_1[0].wtd_volume, 10) /
                10 ** 18
              ).toFixed(2)
            )
          ),
          mtd_volume: formatPrice(
            parseFloat(
              (
                parseInt(response.data.tree.level_1[0].mtd_volume, 10) /
                10 ** 18
              ).toFixed(2)
            )
          ),
        });
        setUsersMatrix(data);
        if (profitCenterId) {
          setCurrentUserId(profitCenterId);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    },
    [beforeUserId, currentUserId]
  );

  const handleCancel = useCallback(() => {
    setShowNewNft(false);
    setSignUpInputs(false);
    setChoiseValue(false);
  }, []);

  const handleMouseDown = useCallback((e, userMatrix) => {
    if (e.button === 2) {
      e.preventDefault();
      setBoxPosition({ x: e.pageX + 10, y: e.pageY });
      setShow(true);
      setShowNewNft(true);
      setUserMatrixSelected(userMatrix);
    }
  }, []);

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    console.log(e.target.value);
  }, []);

  return (
    <Container>
      <div className="container-fluid px-lg-0 container-xxl">
        <div className="row mb-4 mt-4">
          <div className="col-12 mb-2">
            <Welcome>
              <div className="container-fluid">
                <div className="row justify-content-center justify-content-lg-start align-items-center">
                  <div className="col-lg-6 px-0 d-flex align-items-center justify-content-center justify-content-lg-start">
                    <h1 className="h4 h2-xxl mb-0">
                      <span className="fw-bold">Matrix 5x6 -</span> Monthly
                      Residual
                    </h1>
                  </div>
                  <div className="col-lg-6 ps-0">
                    <WalletRow />
                  </div>
                </div>
              </div>
            </Welcome>
          </div>
        </div>
        <BoxMatrix className="bg-gray py-5 px-4">
          <div className={`row justify-content-between button-group `}>
            <div className="col-md-2 my-2 my-md-0 d-flex align-items-center justify-content-center">
              <button
                type="button"
                className={`${
                  firstId === currentUserId ? 'hide my-n5' : ''
                } d-flex align-items-center justify-content-center btn-actions border-gradient rounded-pill`}
                onClick={() =>
                  handleClick({
                    profitCenterId: firstId,
                    firstLine: true,
                  })
                }
                disabled={firstId === currentUserId}
              >
                <img src={activity} alt="Activity" />
                <span>Top</span>
              </button>
            </div>
            <div className="col-md-4 my-2 my-md-0 d-flex align-items-center justify-content-center">
              <div className="w-100 border-gray">
                <div className="d-flex py-1 px-3 desk-small">
                  <input
                    type="text"
                    className="border-0 bg-transparent w-100"
                    placeholder="Search NFT Matrix"
                    onChange={handleChange}
                  />
                  <button type="submit" className="border-0 bg-transparent">
                    <img src={search} alt="Search" className="img-search" />
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-2 my-2 my-md-0 d-flex align-items-center justify-content-center">
              <button
                type="button"
                className={`${
                  firstId === currentUserId ? 'hide my-n5' : ''
                } d-flex align-items-center justify-content-center btn-actions border-gradient rounded-pill`}
                onClick={() =>
                  handleClick({
                    profitCenterId:
                      beforeUserId.length > 0
                        ? beforeUserId[beforeUserId.length - 1]
                        : undefined,
                  })
                }
                disabled={beforeUserId.length === 0}
              >
                <img src={arrow} alt="Arrow Up" />
                <span>One Up</span>
              </button>
            </div>
          </div>
          <div className="row my-0 my-md-5">
            <div className="col-12 px-1 px-xxl-3">
              <div className="box-top-user d-flex flex-wrap flex-md-nowrap justify-content-center justify-content-sm-evenly align-items-center py-3">
                <div className="my-2 my-sm-0 order-1 order-md-0 text-center w-50 w-sm-auto">
                  <p className="mb-0 text-light-gray">
                    {userData.total_members}
                  </p>
                  <p className="mb-0 small normal-xl">Total Members</p>
                </div>
                <div className="d-none d-md-block h-line order-md-0" />
                <div className="my-2 my-sm-0 order-1 order-md-0 text-center w-50 w-sm-auto">
                  <p className="mb-0 text-light-gray">
                    {userData.direct_referrals}
                  </p>
                  <p className="mb-0 small normal-xl">Direct Referals</p>
                </div>
                <div className="my-2 my-md-0 order-0 order-md-1 d-flex justify-content-center align-items-center w-100 w-md-auto">
                  <div className="avatar d-flex align-items-center border-gradient text-center rounded-circle">
                    <Avatar src={userData.nft_img} className="rounded-circle" />
                  </div>
                  <div className="ps-3">
                    <p className="mb-0 text-light-gray">{userData.nft_name}</p>
                    <p className="mb-0 small normal-xl">
                      {userData.screen_name}
                    </p>
                  </div>
                </div>
                <div className="my-2 my-sm-0 order-1 order-md-2 text-center w-50 w-sm-auto">
                  <p className="mb-0 text-light-gray">${userData.mtd_volume}</p>
                  <p className="mb-0 small normal-xl">Earnings This Month</p>
                </div>
                <div className="d-none d-md-block h-line order-md-2" />
                <div className="my-2 my-sm-0 order-1 order-md-2 text-center w-50 w-sm-auto">
                  <p className="mb-0 text-light-gray">$0</p>
                  <p className="mb-0 small normal-xl">Lifetime Earnings</p>
                </div>
              </div>
              <div className="row justify-content-center my-4 px-3">
                {usersMatrix.map((userMatrix) => (
                  <div
                    key={userMatrix.id}
                    className="col-md-10 col-xl px-1 w-xl-20"
                  >
                    <button
                      type="button"
                      className="box-sub-user d-flex align-items-center justify-content-center rounded-pill w-100 py-2 btn-ntf"
                      onClick={(e) => {
                        handleClick({
                          profitCenterId: userMatrix.id,
                        });
                      }}
                      onMouseDown={(e) => handleMouseDown(e, userMatrix)}
                      // disabled={!userMatrix.nft_img}
                    >
                      <div className="w-custom">
                        {userMatrix.id > 0 && (
                          <p className="mb-0 text-start ps-3 small normal-xxl text-light-gray">
                            Profit
                            <br />
                            Center&nbsp;{userMatrix.id}
                          </p>
                        )}
                      </div>
                      <div className="mx-2 text-center">
                        {userMatrix.nft_img ? (
                          <div className="avatar d-flex align-items-center border-gradient text-center rounded-circle">
                            <Avatar
                              src={userMatrix.nft_img}
                              className="rounded-circle"
                            />
                          </div>
                        ) : (
                          <div className="default-avatar" />
                        )}
                      </div>
                      <div className="w-custom">
                        <p className="mb-0 small normal-xxl">
                          {userMatrix.screen_name}
                        </p>
                        <p className="mb-0 small normal-xxl text-green">
                          {userMatrix.rank}
                        </p>
                      </div>
                    </button>
                    <div className="d-flex my-4">
                      {userMatrix.referrers.map((referrer) => (
                        <div
                          key={referrer.id}
                          className="col px-1 d-flex flex-column justify-content-center align-items-center"
                        >
                          <button
                            type="button"
                            className="box-user d-flex justify-content-center align-items-center rounded-circle btn-ntf"
                            onClick={() =>
                              handleClick({
                                profitCenterId: referrer.id,
                                beforeProfitCenterId: userMatrix.id,
                              })
                            }
                            onMouseDown={(e) => handleMouseDown(e, referrer)}
                            // disabled={!referrer.nft_img}
                          >
                            {referrer.nft_img ? (
                              <Avatar
                                src={referrer.nft_img}
                                className="rounded-circle"
                              />
                            ) : (
                              <div className="default-avatar" />
                            )}
                          </button>
                          <BsArrowDownShort color="#E9E9E9" />
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </BoxMatrix>
      </div>
      <BoxNft
        ref={boxNftRef}
        show={userMatrixSelected.nft_name ? show : false}
        positionX={boxPosition.x}
        positionY={boxPosition.y}
        className="d-flex flex-column align-items-center p-3"
      >
        <img src={wallpaper} alt="wallpaper" className="wallpaper w-100" />
        <div className="w-100 d-flex justify-content-between mb-1 px-3">
          <span className="nft-name mb-0 text-center text-start">
            {userMatrixSelected.nft_name}
          </span>
          <span className="new-member mb-0 text-center text-start">
            Prodigy
            <img src={rain} alt="Rain icon" className="ms-2" />
          </span>
        </div>
        <div className="avatar-nft d-flex align-items-center border-gradient text-center ">
          <Avatar src={userMatrixSelected.nft_img} className="img-nft" />
        </div>

        <p className="fw-bold mt-4 mb-0 nft-name-selected">
          {userMatrixSelected.nft_name}
        </p>
        <span className="mb-4">
          Level Qualified:{' '}
          <span className="qualified">{userMatrixSelected.rank}</span>
        </span>
        <div className="row px-2">
          <div className="col-lg-6 d-flex flex-column align-items-center mb-4">
            <p className="mb-0">{userMatrixSelected.total_members}</p>
            <span>Total Members</span>
          </div>
          <div className="col-lg-6 d-flex flex-column align-items-center mb-4">
            <p className="mb-0">{userMatrixSelected.direct_referrals}</p>
            <span>Direct Referals</span>
          </div>
          <div className="col-lg-6 d-flex flex-column align-items-center mb-4">
            <p className="mb-0">$125</p>
            <span>Subs. Balance</span>
          </div>
          <div className="col-lg-6 d-flex flex-column align-items-center mb-4">
            <p className="mb-0">04-APR-23</p>
            <span>Due Date</span>
          </div>
          <div className="col-12">
            <button type="button" className="w-100 pay-amount py-3 border-0">
              Pay Amount Due
            </button>
            <button type="button" className="w-100 message py-3 mt-3">
              Message&nbsp;
              <AiOutlineMessage size={30} color="#fff" />
            </button>
          </div>
          <small className="mt-4 text-center">
            Get direct placement referral link
          </small>
          <div className="px-1">
            <p className="p-box mt-3 mb-0">
              <span className="d-block ms-auto">
                <BsPlus size={35} color="#2F3031" className="bsPlus-border" />
              </span>
            </p>
          </div>
        </div>
      </BoxNft>
      <BoxNewNft
        ref={boxNewNftRef}
        show={!userMatrixSelected.nft_name ? showNewNft : false}
        positionX={boxPosition.x}
        positionY={boxPosition.y}
        className="d-flex flex-column align-items-center p-3"
      >
        <img src={wallpaper} alt="wallpaper" className="wallpaper w-100" />
        <div className="w-100 d-flex justify-content-between mb-3 px-3">
          <span className="d-flex align-items-center w-100 new-member fw-light mb-0 text-center text-start">
            <img src={info} alt="info icon" className="me-2" />
            <b className="fw-semibold me-1">Learn how </b>remote sign up works
          </span>
        </div>
        <div className="avatar-nft d-flex align-items-center border-gradient text-center ">
          <Avatar src={userMatrixSelected.nft_img} className="img-nft" />
        </div>

        <p className="fw-bold my-4 nft-name-selected">Empty Matrix Position</p>

        <div className="w-100 px-2">
          <div className="">
            <button
              type="button"
              onClick={() => setSignUpInputs(!signUpInputs)}
              className={`${signUpInputs && 'd-none'} w-100 sign-up py-3`}
            >
              Sign Up New NFT <BsArrowRight color="#fff" size={25} />
            </button>
          </div>
          {signUpInputs && (
            <Form ref={formRef} onSubmit={() => console.log('submit')}>
              <div className={`${choiseValue && 'd-none'}`}>
                <small className="d-block mb-3 text-center">
                  Enter destination wallet
                </small>
                <div className="d-flex px-2 enter-infos">
                  <Input
                    name="destination"
                    className="height bg-transparent border-0"
                  />
                </div>
                <small className="d-block mt-4 mb-3 text-center">
                  Get direct placement referral link
                </small>
                <div className="d-flex px-2 enter-infos">
                  <Input
                    name="authorization"
                    className="height bg-transparent border-0"
                  />
                  <button
                    type="button"
                    className="d-block ms-auto bg-transparent border-0"
                  >
                    <BsPlus
                      size={44}
                      color="#6D50D0"
                      className="bsPlus-border"
                    />
                  </button>
                </div>
                <small className="d-block mt-4 mb-3 text-center">
                  Enter New Member AutoAffiliate ID
                </small>
                <div className="d-flex px-2 enter-infos">
                  <Input
                    name="autoaffiliate_id"
                    className="height bg-transparent border-0"
                  />
                </div>
                <button
                  onClick={() => setChoiseValue(true)}
                  type="button"
                  className="w-100 mt-4 pay-amount py-3 border-0"
                >
                  NEXT
                </button>
              </div>
              <div className={`${!choiseValue && 'd-none'}`}>
                <h2>How Many Profit Centers?</h2>
                <div className="d-flex text-center value-token position-relative text-center justify-content-center">
                  <p className="mb-0 d-flex align-items-center">
                    <span className="pe-3">$</span>
                    {formatPrice(valueRange * 75).slice(0, -3)}
                  </p>
                  <img
                    src={info}
                    alt="Info"
                    title="Coming soon"
                    className="ms-3"
                  />
                </div>
                <div className="d-flex text-center position-relative text-center justify-content-center mt-2 mb-4">
                  <div className="eth-amt-wrap d-flex text-center position-relative text-center justify-content-center">
                    <div className="d-flex w-100 inner">
                      <div className="d-flex align-items-center justify-content-between w-100 txt">
                        <img src={ethLogo} alt="Logo" />
                        <span className="d-flex mx-auto">0.000&nbsp;ETH</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-center mb-3">
                  <input
                    type="range"
                    name="teste"
                    min={1}
                    max={6}
                    onChange={(e) => {
                      setValueRange(parseInt(e.target.value, 10));
                    }}
                    value={valueRange}
                    className="slider-pic"
                  />
                  <span className="thumb" />
                  <span className="py-0 text-white h5 mb-0">
                    &nbsp;{valueRange}
                  </span>
                </div>
                <div className="mb-2">
                  <span className="d-block text-center text-change">
                    Use slider to change amount
                  </span>
                </div>
                <div className="d-flex justify-content-between">
                  <button
                    onClick={handleCancel}
                    type="button"
                    className="w-40 mt-4 btn-cancel border-0"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="w-55 mt-4 pay-amount border-0"
                    onClick={() => {
                      setShowModalPleaseConfirm(true);
                      handleCancel();
                    }}
                  >
                    NEXT
                  </button>
                </div>
              </div>
            </Form>
          )}
        </div>
      </BoxNewNft>
      <ModalPleaseConfirm
        show={showModalPleaseConfirm}
        aaName="AA Name"
        aaId="7Yhudh334"
        walletAddress="0xksdfjiejwe23344dfjsjd8ddh333"
        memberName="John V."
        handleCancel={() => setShowModalPleaseConfirm(false)}
        handleAccept={() => console.log('accept')}
      />
      <Loading
        type="dark"
        srcImg={logoWhite}
        text="PLEASE WAIT..."
        active={loading}
      />
    </Container>
  );
};

export default Matrix5x6;
