import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';

export const Modal = styled(ModalComponent)`
  .modal-content {
    background: #171719 !important;
    border-radius: 60px;
    position: relative;

    h2 {
      color: #dadada;
      font-family: 'Poppins';
      font-size: 48px;
      font-weight: 700;
      letter-spacing: 0.96px;
    }

    h3 {
      color: #dadada;
      font-family: 'Poppins';
      font-size: 26px;
      font-weight: 600;
      span {
        color: #a6a2a2;
        font-weight: 300;
      }
    }

    h4 {
      color: #dadada;
      font-family: 'Poppins';
      font-size: 26px;
      font-weight: 600;
      line-height: normal;
      margin: 1rem 0;
    }

    h6 {
      text-align: center;
      color: #7d7777;
      font-family: 'Quicksand';
      font-size: 26px;
      font-weight: 400;
      letter-spacing: -1.04px;
    }

    p {
      color: #a6a2a2;

      font-family: 'Poppins';
      font-size: 22px;
      font-weight: 300;
      line-height: normal;
      margin-bottom: 0px;
      span {
        color: #dadada;
        font-weight: 600;
      }
    }

    li::marker {
      color: #dadada;
    }

    label div label {
      align-items: start;
    }
    label div label div {
      min-width: 16px;
      min-height: 16px;
    }

    label div span {
      color: #dadada;

      font-family: 'Quicksand';
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 25px;
      letter-spacing: -0.64px;
      span {
        font-weight: 600;
      }
      b {
        font-weight: 500;
      }
    }

    svg {
      color: #fff !important;
    }

    .btn-logout {
      position: absolute;
      top: 3%;
      left: 5%;
      border: none;
      background-color: transparent;
      color: #ff1a50;
      font-size: 12px;
      font-weight: 600;
      :hover {
        text-decoration: underline;
      }
    }

    .scroll-click {
      color: #ff1a50;
      font-size: 16px;
    }

    .scroll-text {
      color: #f5f5f5;
      background-color: #ff1a50;
      border-radius: 14px;
      display: inline-flex;
      font-size: 16px;
      align-items: center;
    }

    .scroll-text div {
      margin: 0 7px !important;
    }

    .scroll-click div {
      margin: 0 3px 0 1px !important;
    }

    .scroll-click-div {
      //display: inline-flex;
      align-items: center;
    }

    .opacity-25 {
      opacity: 0.25;
    }
    .opacity-100 {
      opacity: 1;
    }
    .pe-none {
      pointer-events: none;
    }
    .cursor-allowed {
      cursor: not-allowed;
    }

    .bg-content {
      border-radius: 22px;
      background: transparent;
    }

    .content {
      padding-right: 10px;
      height: 550px;
      overflow: auto;
      h2 {
        color: #dadada;
        font-family: 'Poppins';
        font-size: 48px;
        font-weight: 700;
        letter-spacing: 0.96px;
        b {
          background: linear-gradient(91deg, #addcb7 0.31%, #5eba72 25.21%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-weight: 800;
        }

        span {
          font-weight: 400;
        }
      }
    }

    .modal-close {
      color: #021027;
      position: absolute;
      right: 20px;
      top: 20px;
    }

    .btn-download {
      background-color: #f9f9f9;
      border-radius: 14px;
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition-duration: 0.3s;

      :hover {
        box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
      }
    }

    .btn-decline {
      background-color: #2b3247;
      color: #fff;
      border-radius: 20px;
      font-weight: 700;
      font-size: 24px;
      min-width: 48%;
      height: 75px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition-duration: 0.3s;

      :hover {
        box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
      }
    }

    .btn-accept {
      background-color: #696ffd;
      border-radius: 20px;
      color: #fff;
      border-radius: 20px;
      font-weight: 700;
      font-size: 24px;
      min-width: 48%;
      height: 75px;
      display: flex;
      align-items: center;
      justify-content: center;

      transition-duration: 0.3s;
      //margin-left: 25px;

      :hover {
        box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
      }
    }
  }

  @media screen and (min-width: 1200px) {
    .modal-content label div label {
      align-items: center;
    }
    .modal-xl {
      zoom: 0.7;
    }
  }

  @media screen and (min-width: 1799px) {
    .modal-xl {
      max-width: 1100px;
      zoom: 0.85;
    }
  }

  @media screen and (max-width: 991px) {
    .modal-content h2 {
      font-size: 23px;
    }

    .modal-content h3,
    .modal-content h4 {
      font-size: 20px;
    }

    .modal-content p {
      font-size: 16px;
    }
  }

  @media screen and (max-width: 575px) {
    .modal-content h2 {
      font-size: 5vw;
    }

    .modal-content p {
      font-size: 15px;
    }

    label div {
      display: block;
    }

    label div label {
      display: block;
    }
    .modal-content label {
      text-align: center;
    }

    .modal-content label div span {
      font-size: 16px;
    }
    .modal-content label div label div {
      margin: 0 auto;
      display: flex;
      align-items: center;
    }

    .modal-content .btn-accept {
      width: 250px;
    }
  }
`;
