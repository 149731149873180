import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';
import graph from '~/assets/defaults/graph-trading.png';

export const Container = styled.div``;
export const Welcome = styled.div`
  h1 {
    color: #f1f1f1;

    font-weight: 300;

    span {
      background: linear-gradient(91deg, #addcb7 0.31%, #5eba72 25.21%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: 700;
    }
  }
`;

export const Trade = styled.div`
  .bg-graph {
    background-image: url(${graph});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 34px;
    width: 100%;
    height: calc(100% - 64px);

    img {
      width: 48px;
      height: 48px;
    }
    button {
      color: #0d0d0d;
      border: 1px solid #070a0e;

      background: rgba(255, 255, 255, 0.4);

      backdrop-filter: blur(10px);
    }
    h4 {
      color: #0d0d0d;
      font-size: 22px;
      font-weight: 400;
      line-height: 38px;
      letter-spacing: 0.33px;
      span {
        font-size: 12px;
        font-weight: 300;
        letter-spacing: 0.18px;
      }
    }
  }

  .price {
    h3 {
      color: #1a1d1e;
      font-weight: 400;
      letter-spacing: 0.16px;
    }
    span {
      color: #0d0d0d;
      font-size: 34px;
      font-style: normal;
      font-weight: 600;
      line-height: 38px;
      letter-spacing: 0.51px;
    }
  }

  .top {
    h3 {
      color: #a1a1a1;
      font-size: 16px;
      font-style: italic;
      font-weight: 300;
    }
  }

  .top-trades {
    border-radius: 34px;
    background: #212122;
    h4 {
      color: #fff;
      font-size: 22px;
      font-weight: 400;
    }
  }

  .bg-coins {
    border-radius: 30px;
    background: #1c1c1d;
    img {
      width: 24px;
      height: 24px;
    }
  }

  .times h4 {
    color: #e1e1e1;
    font-size: 12px;
    font-style: italic;
    font-weight: 300;
  }

  .times p {
    border-radius: 30px;
    background: #212122;
    padding: 8px 13px;
  }

  .arrow-positive {
    clip-path: polygon(50% 0%, 100% 100%, 0% 100%);
    background-color: #00f9ab !important;
    width: 12px;
    height: 9px;
  }

  .arrow-negative {
    transform: rotate(180deg);
    clip-path: polygon(50% 0%, 100% 100%, 0% 100%);
    background-color: #f92c2c !important;
    width: 12px;
    height: 9px;
  }

  .positive {
    color: #00f9ab;
  }
  .negative {
    color: #f92c2c;
  }

  .header-tab {
    display: none;
  }

  .table {
    background-color: transparent !important;
  }

  .table img {
    width: 23px;
    height: 23px;
  }

  div.tableData div:not(.arrow-positive):not(.arrow-negative) {
    color: #fff;
    background-color: transparent !important;
  }

  div.tableData .rdt_TableRow div {
    font-size: 16px;
  }

  div.tableData .rdt_TableRow {
    border-bottom: 1px solid #212325;
  }

  div.tableData .rdt_TableHead {
    border-top: 1px solid #212325;
    border-bottom: 1px solid #212325;
  }

  div.tableData .rdt_TableHeadRow {
    min-height: 42px;
  }

  div.tableData .rdt_TableRow {
    min-height: 60px;
  }

  .rdt_TableCol,
  .rdt_TableCell {
    justify-content: center;
  }

  .btns {
    display: flex;
  }

  .btns button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border-radius: 15px;
    border: 1px solid #212325;
    min-width: 30px;
    min-height: 30px;
  }
`;

export const Modal = styled(ModalComponent)`
  .modal-content {
    background: #171719 !important;
    border-radius: 35px;
    position: relative;

    h2 {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 48px;
      color: #dadada;
    }

    .btns-actions {
      background-color: #101012;
      border-radius: 20px;
      display: flex;
      justify-content: space-between;
    }

    .btns-actions button {
      background-color: transparent;
      border-radius: 15px;
      font-size: 15px;
      color: #6f767e;
      border: 1px solid #101012;
      height: 50px;
      min-width: 48%;
      font-weight: 600;
      transition: all 0.2s ease-in-out;
    }

    .btns-actions .active {
      border-radius: 16px;
      border: 1px solid #393939;
      color: #8c8c8c;
      background: #1d1d1f;
    }

    .modal-title,
    label span {
      color: #dadada;
    }

    .fund-wallet-modal-title {
      color: #dadada;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Inter;
      font-size: 34px;
      font-style: normal;
      font-weight: 600;
      // line-height: 114px; /* 335.294% */
    }
    .fund-wallet-modal-title-2 {
      color: #666;
      text-align: center;
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 300;
      line-height: 24px; /* 133.333% */
    }
    .amt-needed-text {
      color: #fff;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Inter;
      font-size: 28px;
      font-style: normal;
      font-weight: 500;
      // line-height: 114px; /* 407.143% */
    }

    .amt-needed-text span {
      color: #dadada;
      font-family: 'Inter';
      font-size: 28px;
      font-weight: 400;
    }

    .amt-needed-text i {
      font-size: 20px;
    }

    .amt-needed-text i img {
      width: 20px;
      height: 20px;
    }

    .eth-needed-text,
    .eth-needed-amount {
      color: #767676;

      text-align: center;
      font-family: 'Inter';
      font-size: 18px;
      font-weight: 300;
      line-height: 24px;
    }

    .amt-needed-input input {
      color: #fcfcfc;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: 'Inter';
      font-size: 64px;
      font-style: normal;
      font-weight: 600;
      text-align: center;
      // line-height: 114px; /* 407.143% */
    }

    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    .amt-needed-input input::placeholder {
      // color: #efefef;
      color: #5d5b5b;
      font-family: Inter;
      font-size: 64px;
      font-style: normal;
      font-weight: 300;
      line-height: 24px; /* 133.333% */
    }

    .input {
      border: 1px solid #4f4f4f;
      border-radius: 24px;
      height: 160px !important;
      text-align: center;
      margin-bottom: 2rem;

      input::placeholder {
        color: #5d5b5b;
        font-weight: 600;
        font-size: 64px;
      }
    }

    .modal-close {
      color: #e9e9e9;
      position: absolute;
      right: 20px;
      top: 20px;
    }

    .claim {
      background: #a3c0e1;
      border-radius: 10px;
      font-weight: 700;
      font-size: 10px;
      color: #0927c6;
      transition-duration: 0.3s;

      :hover {
        opacity: 0.8;
      }

      :disabled {
        opacity: 0.7;
      }
    }

    .buy {
      padding: 13px 16px;
      border-radius: 10px;
      background: #00f9ab;
      color: #1e2322;
      text-align: center;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      :hover {
        opacity: 0.8;
      }

      :disabled {
        opacity: 0.7;
      }
    }

    .texts p:nth-child(1),
    .texts p:nth-child(4) {
      font-family: 'Poppins';
      font-weight: 400;
      font-size: 22px;
      line-height: 118%;
      color: #a6a2a2;
    }

    .texts .terms {
      background: #1d1d1f;
      border-radius: 12px;
      font-weight: 300;
      font-size: 16px;
      padding: 3px;
      span {
        font-weight: 300;
        font-size: 16px;
        line-height: 36px;
        color: #7d7777;
      }
    }

    .color-span {
      color: #a6a2a2 !important;
      font-weight: 500 !important;
    }

    .btn-confirm {
      background: #696ffd;
      border-radius: 20px;
      border: none;
      color: #fff;
      font-weight: 700;
      font-size: 24px;
      padding: 20px;
      transition-duration: 0.3s;
      :hover {
        opacity: 0.8;
      }
    }

    .btn-done {
      background: #2b3247;
      border-radius: 20px;
      border: none;
      color: #fff;
      font-weight: 700;
      font-size: 24px;
      padding: 20px;
      transition-duration: 0.3s;
      :hover {
        opacity: 0.8;
      }
    }

    .btn-cancel {
      background: #0f0f0f;
      border-radius: 20px;
      border: none;
      height: 75px;
      color: #fff;
      font-weight: 600;
      font-size: 22px;
      padding: 20px;
      transition-duration: 0.3s;
      :hover {
        opacity: 0.8;
      }
    }

    .btn-fund-wallet {
      background: #00f9ab;
      border-radius: 20px;
      border: none;
      height: 75px;
      color: #000;
      font-weight: 600;
      font-size: 24px;
      padding: 20px 50px;
      transition-duration: 0.3s;

      :hover {
        opacity: 0.8;
      }
    }

    .error-message {
      margin-top: 10px;
      margin-left: 10px;
      color: #ff1a50;
    }
  }

  @media screen and (min-width: 992px) {
    .modal-dialog {
      zoom: 0.7;
    }
  }

  @media screen and (min-width: 1799px) {
    .modal-dialog {
      zoom: 1;
    }
  }

  @media screen and (max-width: 991px) {
    h2 {
      font-size: 24px;
    }

    .btn-fund-wallet {
      padding: 20px 30px !important;
    }
  }
`;
